<template>
  <div class="px-0">
    <button
      id="buttonAbrirCaixa"
      type="submit"
      class="btn_default px-2 py-2"
      :disabled="disabled"
      @click.prevent="$route.path == '/logout' ? fecharCaixa() : abrirCaixa()"
    >
      <div v-if="componetCurrent === 'retirarDinheiro'">
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
          v-show="disabled"
        ></span>
        {{
          !disabled
            ? `${msg} ( ${valueConvert(
                !$parent.totalValue ? 0 : $parent.totalValue
              )} )`
            : ""
        }}
      </div>
      <div v-else>
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
          v-show="disabled"
        ></span>
        {{
          !disabled
            ? `${msg} ( ${$options.filters.currency($parent.totalValue)} )`
            : ""
        }}
      </div>
    </button>
  </div>
</template>

<script>
import HTTP from "@/api_system";
export default {
  props: ["msg"],
  data() {
    return {
      componetCurrent: undefined,
      disabled: false,
      id: "",
      token: "",
    };
  },
  mounted() {
    this.componetCurrent = this.$parent.nameComponent;
    this.$store.commit("trocarValuesComponent", false);
  },
  methods: {
    abrirCaixa() {
      this.disabled = true;
      HTTP.post(
        `services/app/Cashier/OpeningCashier`,
        {
          inicialValue: this.$parent.totalValue,
          inicalPrints: this.$parent.qtdImpress,
        },
        {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`,
          },
        }
      )
        .then((response) => {
          this.$notify({
            group: "erros",
            type: "sucess",
            text: `<i class="icon ion-close-circled"></i>Seja bem vindo(a) ${this.$store.getters.getUser.displayName}`,
          });
          this.$store.commit("SET_CAIXAID", response.data.result.id);

          this.$store.commit("SET_VALIDATION", true);
          this.$nextTick(() => {
            this.disabled = false;
            this.$router.push("/");
          });
        })
        .catch((error) => {
          this.disabled = false;
          this.$notify({
            group: "erros",
            type: "error",
            text: `<i class="icon ion-close-circled"></i> ${error.response.data.error.message}`,
          });
        });
    },
    fecharCaixa() {
      if (this.componetCurrent === "fecharCaixa") {
        let value = this.$parent.totalValue + this.$parent.totalValueCartao;
        sessionStorage.setItem("notasValue", this.$parent.sessionStorageValue)

        if (this.$parent.totalValue > 0) {
          this.$store.commit("valorCx", value);
          this.$emit("trocaComponent", "retirarDinheiro");
        } else {
          this.disabled = false;
          this.$notify({
            group: "erros",
            type: "error",
            text: `<i class="icon ion-close-circled"></i> Digite um valor`,
          });
        }
      } else if (this.componetCurrent === "retirarDinheiro") {
        this.id = this.$store.state.caixa.idCaixa;
        this.token = this.$store.getters.getterToken;
        this.disabled = true;
        sessionStorage.setItem("notasValueRetirada", this.$parent.sessionStorageRetirada)
        HTTP.post(
          `services/app/Cashier/ClosingCashier`,
          {
            id: this.$store.state.caixa.idCaixa,
            endValue: this.$store.getters.valueCaixa,
            endPrints: 0,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getterToken}`,
            },
          }
        )
          .then((res) => {
           
            this.retirada();

            sessionStorage.removeItem("modal");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    retirada() {
      HTTP.post(
        `services/app/Cashier/CashWithdrawal`,
        {
          cashierId: this.$store.state.caixa.idCaixa,
          value: this.$parent.totalValue,
        },
        {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`,
          },
        }
      )
        .then((res) => {
          this.$emit("trocaComponent", "comfirm");

          setTimeout(() => {
            this.$store.commit("trocarValuesComponent", true);
          }, 400);

          let values = [];
          values.push(res.data.result);

          this.$store.commit("fecharCx", values);
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style>
@media screen and (max-width:767px){
  #buttonAbrirCaixa{
    background-color: #25D366;
    border: none;
    height: 47px;
    font-size: 18px;
  }
}
</style>
