<template>
  <div
    class="abrir_caixa row justify-content-center d-flex align-items-baseline"
  > 
    <div class="col-md-5" id="imageAndTxtCont">
      <div class="image_div position-relative mx-auto">
        <img :src="getImg(user.photo)" class="img_user" @error="errorImage" />
      </div>
      <div v-if="(this.windowSize < 768)">
        <p class="title">
          Olá,
          <br />
          <strong>{{ user.displayName }}!</strong>
          <Modal :verify="virifyBool" :idCaixa="id" :day="dayCurrent" />
        </p>
      </div>
    </div>
    <div class="col-md-7 pr-0">
      <div class="name">
        <p class="title" v-if="(this.windowSize >= 768)">
          Olá,
          <br />
          <strong>{{ user.displayName }}!</strong>
          <Modal :verify="virifyBool" :idCaixa="id" :day="dayCurrent" />
        </p>
        <p class="texto mb-0">
          Enquanto organizamos as suas coisas por aqui, informe, por favor, como
          está o
          <strong>caixa hoje.</strong>
        </p>
      </div>
    </div>
    <div v-if="virifyBool === false" id="caixa_cp_cont">
      <div class="m-3 loaders" v-if="load">
        <loader color="#e0a638" size="100" />
      </div>
      <div class="caixa_cp col-md-12 px-0 my-3" v-else>
        <caixa class="col-md-12" @changeValueNota="changeValueNota" />
      </div>
      <div v-if="(this.windowSize > 768)"><botao_caixa  msg="Abrir caixa" class="col-md-12" v-if="!load"/></div>
    </div>
    <div v-if="(this.windowSize < 768)" class="buttonOpenCont"><botao_caixa msg="Abrir caixa" class="col-md-12" v-if="!load" id="buttonOpen"/></div>
  </div>
</template>

<script>
import HTTP from "@/api_system";
import Modal from "@/components/modal/modalCaixaLogin";
import caixa from "@/components/caixacp/_Caixa";
import botao_caixa from "@/components/caixacp/_Caixa_btn";
import loader from "@/components/utils/_Load";
export default {
  components: {
    caixa,
    botao_caixa,
    Modal,
    loader,
  },
  data() {
    return {
      msg: "Abrir o caixa",
      totalValue: 0,
      virifyBool: false,
      id: null,
      dayCurrent: false,
      load: true,
      windowSize: 0
    };
  },
  mounted() {
    this.windowSize = $(window).width()
    if (this.$route.path === "/abrir-caixa") {
      this.verifiyCaixa();
    }
  },
  methods: {
    async verifiyCaixa() {
      try {
        let { data } = await HTTP.post(
          `services/app/Cashier/VerifyCashierDay`,
          {},
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getterToken}`
            }
          }
        );
        const { cashierId, cashierToday } = data.result;
        this.id = null;
        if (cashierId) {
          this.virifyBool = true;
          this.id = cashierId;

          this.$store.commit("SET_CAIXAID", cashierId);
          if (cashierToday) {
            this.dayCurrent = true;
            
          }
        }

        this.load = false;
      } catch (error) {
        console.log(error);
        this.load = false;
      }
    },
    changeValueNota(value) {
      this.totalValue = value;
    }
  },
  watch: {},
  computed: {
    user() {
      return this.$store.state.login.userLogin;
    }
  }
};
</script>

<style scoped>
.input_default {
  color: #2474b2;
}
.title strong {
  margin-bottom: 0.1rem !important;
  color: #2474b2;
}
.texto {
  color: #868686 !important;
  font-size: 13px;
}
.title {
  color: #868686 !important;
}
.name {
  text-align: left;
}
.image_div {
  overflow: hidden;
  width: 85px;
  overflow: hidden;
  height: 85px;
  border-radius: 50%;
  border: 5px solid #2474b2;
}

.image_div .img_user {
  object-fit: cover;
  height: auto;
  min-height: 100%;
  width: 100%;
  object-position: top;
}
.caixa_cp {
  max-height: 230px;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
}
/* width */
.caixa_cp::-webkit-scrollbar {
  width: 5px;
  background: #e3e3e3;
}

/* Track */
.caixa_cp::-webkit-scrollbar-track {
  border-radius: 0px;
}

/* Handle */
.caixa_cp::-webkit-scrollbar-thumb {
  background: #2474b2;
  border-radius: 0px;
}

.loaders {
  height: 165px;
}
@media screen and (max-width: 767px) {
  .abrir_caixa{
    flex-direction: column;
  }
  #imageAndTxtCont{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .image_div {
    margin-right: 15px!important;
    margin-left: 0!important;
  }
  .title{
    text-align: start;
    color: #f28236!important;
    font-size: 16px;
  }
  .title strong{
    text-align: start;
    color: #f28236;
    font-size: 16px;
  }
  .texto{
    text-align: center;
    color: #868686 !important;
    font-size: 17px;
    margin-top: 5%;
  }
  .caixa_cp{
    max-height: 275px!important;
  }
  #caixa_cp_cont{
    background-color: #E9E9EB;
    border-radius: 16px;
    margin-top: 10%
  }

  .buttonOpenCont{
    width: 100%;
    margin-top: 10%
  }
}
@media screen and (max-height:749px){
  #caixa_cp_cont{
    background-color: #E9E9EB;
    border-radius: 16px;
    margin-top: 2%
  }
  #columNotasCont {
    width: 106%;
  }
}
</style>
